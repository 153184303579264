import axios from "axios";
import React, { useEffect, useState } from "react";
import { Page, pdfjs } from "react-pdf"; // Import the Document and Page components from react-pdf
import "./App.css";
function App() {
  const [pdf1, setPdf1] = useState(null);
  const [pdf2, setPdf2] = useState(null);
  const [mergedPdfUrl, setMergedPdfUrl] = useState(null);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  const handlePdf1Change = (e) => {
    setPdf1(e.target.files[0]);
  };

  const handlePdf2Change = (e) => {
    setPdf2(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("pdf1", pdf1);
    formData.append("pdf2", pdf2);
    console.log("pdf1", pdf1);
    console.log("pdf2", pdf2);
    console.log("formData", formData); // Log formData here

    try {
      const response = await axios.post(
        "http://138.201.221.69:5000/dummy_post",
        formData
      );
      console.log("Merged PDF URL:", response.data.merged_pdf_url);
      setMergedPdfUrl(response.data.merged_pdf_url); // Update the mergedPdfUrl state with the URL
    } catch (error) {
      console.error("Error merging PDFs:", error);
    }
  };
  const handleDownloadPdf = async () => {
    try {
      const response = await axios.get(mergedPdfUrl, {
        responseType: "blob",
      });

      // Create a temporary anchor element to initiate the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "merged_pdf.pdf");
      document.body.appendChild(link);
      link.click();

      // Clean up the temporary anchor element
      link.parentNode.removeChild(link);

      // After the download is complete, trigger the removal of the merged PDF from the server
      await axios.get(
        "http://138.201.221.69:5000/remove_file/final_output.pdf"
      );
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <div className="App">
      <h1 className="app-title">PDF Merge App</h1>
      <form onSubmit={handleSubmit} className="form">
        <div className="form-input">
          <label htmlFor="pdf1">Shipping label:</label>
          <input type="file" id="pdf1" onChange={handlePdf1Change} />
        </div>
        <div className="form-input">
          <label htmlFor="pdf2">Packing slip</label>
          <input type="file" id="pdf2" onChange={handlePdf2Change} />
        </div>
        <button
          type="submit"
          className={!pdf1 || !pdf2 ? "opacity form-button" : "form-button "}
          disabled={!pdf1 || !pdf2}
        >
          {!pdf1 || !pdf2 ? "Select pdf-s" : "Merge PDFs"}
        </button>
      </form>
      <button onClick={handleDownloadPdf} className="form-button">
        Download PDF
      </button>
    </div>
  );
}

export default App;
